import React, {useContext, useEffect, useState, useCallback, Suspense} from 'react';
import '../Styles/App.css';
import {ReactComponent as Process1} from '../Images/Icons/01.svg';
import {ReactComponent as Process2} from '../Images/Icons/02.svg';
import {ReactComponent as Process3} from '../Images/Icons/03.svg';
import {MainConsent, SelfConsent, PartnerConsents} from "./Consents";
import {useHistory} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {BorrowerContext} from "./App";
import Api from "./api";
import {ErrorPage, GenerateError, NinjaLoader} from "./Tools";
import LogoPick from "./LogoPick";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


function Welcome () {
    let history = useHistory();
    const { t, i18n } = useTranslation();
    const borrower_context_data = useContext(BorrowerContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error_resp, setError] = useState(null);
    const [show_full, setShowFull] = useState(false);
    const [can_go_further, setCanGoFurther] = useState(false);
    //const [consent_message, setConsentMessage] = useState(t('welcome_consents_are_mandatory'));
    const [main_consent, setMainConsent] = useState({
        "checked": false,
        "id": 0,
        "localized_title": "",
        "localized_text": "",
        "localized_link": null,
        "localized_link_label": null});

    const [self_consent, setSelfConsent] = useState([{}]);

    const [partner_consent, setPartnerConsent] = useState({});

    function canGoFurther() {
        let can = true;
        if(main_consent.checked === false) {
            can = false;
        }
        for(let i=0; i<self_consent.length; i++) {
            if(self_consent[i].checked === false){
                can = false;
            }
        }
        if(partner_consent.checked === false){
            can = false;
        }
       // if(can === true){
        //    setConsentMessage('');
        //} else {
        //    setConsentMessage(t('welcome_consents_are_mandatory'));
       // }
        setCanGoFurther(can);
    }
    function showFullToggle() {
        setShowFull(!show_full);
    }

    function mainConsentChange() {

        let val = !main_consent.checked;
        main_consent.checked = val;
        setMainConsent(main_consent);

        let new_self = self_consent.map(function (row) {
            row.checked = val;
            return row;
        });
        setSelfConsent(new_self);

        let new_partners = partner_consent;
        new_partners.checked = val;
        setPartnerConsent(new_partners);
        canGoFurther();
    }

    function selfConsentChange(id) {

        let new_self = [];
        for(let i=0; i<self_consent.length; i++) {
            let row = self_consent[i]
            if(row.id === id) {
                row.checked = !row.checked;
            }
            new_self.push(row);
        }
        setSelfConsent(new_self);
        canGoFurther();
    }

    function partnerConsentChange() {

        let val = !partner_consent.checked;
        let new_partners = partner_consent;
        new_partners.checked = val;
        setPartnerConsent(new_partners);
        canGoFurther();
    }

    function setConsents() {
        if(can_go_further === true) {
            /*let accepted_consents = [
                {"id":main_consent.id, "accept": true}
            ];
            for(let i=0; i<self_consent.length; i++) {
                accepted_consents.push({"id":self_consent[i].id, "accept": true});
            }
            for(let i=0; i<partner_consent.organizations.length; i++) {
                for(let j=0; j<partner_consent.organizations[i].consents.length; j++) {
                    accepted_consents.push({"id":partner_consent.organizations[i].consents[j].id, "accept": true})
                }
            }*/

            Api.post('/consent/accept',{})
                .then(
                    (result) => {
                        let response = result.data;
                        if(response.code === 0) {
                            history.push('/loan');
                        } else {
                            setError(GenerateError(response.errors[0].code));
                        }
                    },

                    (error) => {
                        setError(GenerateError());
                    }
                )
        }
    }
    useEffect(() => {
        borrower_context_data.setActiveStep(0);
    },[]);

    useEffect(() => {
        Api.defaults.headers.post['Ninja-Lang'] = i18n.language;
        Api.post('/consent/get',{})
            .then(
                (result) => {
                    let response = result.data;

                    if (response.code === 0) {

                        let main = response.data.main[0];
                        main.checked = false;
                        setMainConsent(main);

                        let selfs = response.data.self.map(function (row) {
                            row.checked = false;
                            return row;
                        });
                        setSelfConsent(selfs);
                        let partners = {"checked": false, "organizations": response.data.partners};
                        setPartnerConsent(partners);
                        setIsLoaded(true);
                    } else {
                        setError(GenerateError(response.errors[0].code));
                    }
                    setIsLoaded(true);
                },

                (error) => {
                    setError(GenerateError());
                    setIsLoaded(true);
                }
            )
    },[i18n.language]);

    if(isLoaded === false)
    {
        return <NinjaLoader />
    }
    else
    {
        if (error_resp)
        {
            return <ErrorPage {...error_resp}/>
        }
        else
        {
            return (
                <div>
                    <div><PartnerLogo organization_id={borrower_context_data.loan.organization_id}/></div>
                    <h1 className="centered_text">{t('welcome_title')}</h1>
                    <div className="welcome-text"><p>
                        {borrower_context_data.loan.is_lead !== true && <Trans
                            i18nKey="welcome_text" // the key in your JSON file
                            values={{ // The variables in your i18n entry from the JSON file
                                borrower_name: borrower_context_data.borrower.name ?? "borrower",
                                partner_name: borrower_context_data.loan.organization_name,
                            }}
                            components={[<strong />]}
                        />}
                        {borrower_context_data.loan.is_lead !== true && <Trans
                            i18nKey="welcome_text_lead" // the key in your JSON file
                            values={{ // The variables in your i18n entry from the JSON file
                            borrower_name: borrower_context_data.borrower.name ?? "borrower",
                            partner_name: borrower_context_data.loan.organization_name,
                        }}
                            components={[<strong />]}
                            />}
                    </p></div>
                    <div className="nl-welcome-icon-container top-margin">
                        <div className="nl-welcome-icon-block">
                            <div className="flex-center-row-container">
                                <Process1/>
                            </div>
                            <div className="flex-center-row-container">
                                {t('welcome_icon_01')}
                            </div>

                        </div>
                        <div className="nl-welcome-icon-block">
                            <div className="flex-center-row-container">
                                <Process2/>
                            </div>
                            <div>
                                {t('welcome_icon_02')}
                            </div>

                        </div>
                        <div className="nl-welcome-icon-block">
                            <div className="flex-center-row-container">
                                <Process3/>
                            </div>
                            <div className="flex-center-row-container">
                                {t('welcome_icon_03')}
                            </div>

                        </div>

                    </div>
                    <div className="medium-text top-margin">{t('welcome_pre_consent')}</div>
                    <div className="medium-text"><strong>{t('welcome_pre_consent_second')}</strong></div>
                    <div className="consent-content">
                            <MainConsent main_consent={main_consent} handle={mainConsentChange} showFull={show_full} showFullHandle={showFullToggle}/>
                            {show_full &&
                            <div className={'consent-nested'}>
                                <div className={'consent-self'}>
                                    {self_consent.map((consent) => {
                                        return (
                                            <SelfConsent consent={consent} selfConsentChange={selfConsentChange} key={consent.id}/>
                                        );
                                    })}
                                </div>
                                <div className={'consent-partners'}>
                                    <div>
                                        <PartnerConsents partner_consent={partner_consent} partnerConsentChange={partnerConsentChange}/>
                                    </div>

                                </div>
                            </div>
                            }
                    </div>
                    <div><p className="small-text grayed-text">{t('welcome_consents_expand')}</p></div>
                    {!can_go_further && <div><p className="consent-hint">
                        <ErrorOutlineIcon className="tools-icon"/> {t("welcome_consents_are_mandatory")}
                    </p></div>}
                    <div className="flex-center-row-container">
                        <div className="top-margin">
                            <button className="nl-button nl-button-welcome-start" disabled={!can_go_further} onClick={() => setConsents()}>
                                <strong>{t('welcome_start')}</strong><br/><span className="small-text">{t('welcome_start_description')}</span>
                            </button>
                        </div>
                    </div>
                    <Suspense fallback={<div></div>}>
                        <OurPartners/>
                    </Suspense>

                </div>
            );
        }
    }

}

export default Welcome;


export function PartnerLogo(props) {
    const { t, i18n } = useTranslation();

    return (
        <div className="flex-row-container-right logo-block">
            <div className="medium-text grayed-text">{t('partner_logo_text')}</div>
            <div><LogoPick organization_id={props.organization_id} /></div>
        </div>
    );

}

export function OurPartners() {
    const { t, i18n } = useTranslation();
    return (
        <div>

            <div className="top-margin">
                <h3>{t('our_partners_title')}</h3>
            </div>
            <div className="flex-center-row-container top-margin">
                <img src={require('../Images/OurPartners/takto.svg').default}/>
                <img src={require('../Images/OurPartners/cs.svg').default}/>
                <img src={require('../Images/OurPartners/sbr_bank.svg').default}/>
                <img src={require('../Images/OurPartners/solcredit.svg').default}/>
            </div>
        </div>
    );
}